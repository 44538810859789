<template>
    <popup-modal ref="popup">
        <h2 style="margin-top: 0">{{ title }}</h2>
        <p>{{ message }}</p>
        <div class="btns">
            <div class="btn btn__large" @click="_cancel">{{ cancelButton }}</div>
            <div class="btn btn__large" :class="[ dangerColors ? 'btn__danger' : 'btn__safe' ]" @click="_confirm">{{ okButton }}</div>
        </div>
    </popup-modal>
</template>

<script>
import PopupModal from './PopupModal.vue'

export default {
    name: 'ConfirmDialogue',

    components: { PopupModal },

    data: () => ({
        title: undefined,
        message: undefined,
        okButton: undefined, 
        cancelButton: 'Avbryt',
        dangerColors: true, 
        
        // Private variables
        resolvePromise: undefined,
        rejectPromise: undefined,
    }),

    methods: {
        show(opts = {}) {
            this.title = opts.title
            this.message = opts.message
            this.okButton = opts.okButton
            if (opts.cancelButton) {
                this.cancelButton = opts.cancelButton
            }
            if (typeof(opts.dangerColors) == "boolean") {
                this.dangerColors = opts.dangerColors
            }
            // Once we set our config, we tell the popup modal to open
            this.$refs.popup.open()
            // Return promise so the caller can get results
            return new Promise((resolve, reject) => {
                this.resolvePromise = resolve
                this.rejectPromise = reject
            })
        },

        _confirm() {
            this.$refs.popup.close()
            this.resolvePromise(true)
        },

        _cancel() {
            this.$refs.popup.close()
            this.rejectPromise(new Error('User cancelled the dialogue'))
        },
    },
}
</script>

<style scoped>
.btns {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.btn__danger {
  background-color: #a80000;
  border: 1px solid darkred;
}
.btn__danger:hover {
  background-color: darkred;
 }
.btn__safe {
  background-color: #0b8b45;
  border: 1px solid #096432;
}
.btn__safe:hover {
  background-color: #096432;
 }


</style>
