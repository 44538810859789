<template>
  <div class="presentation__container">
    <h1>Hantera Presentationer</h1>
    <input ref="copybox" id="copybox" />
    <div class="presentationlist__container">
      <div v-for="presentation in getPresentations" :key="presentation.id" class="presentation__list">
        {{ presentation.title }}
        <div class="linkgroup">
          <div class="button__delete" @click="doDelete(presentation.id)">Radera presentation</div>
          <div class="btn" @click="handleCopy(presentation.id, $event)"><font-awesome-icon icon="copy" class="icon"/>Kopiera länk<div class="copied-alert">Länk kopierad</div></div>
          <router-link class="btn" :to="{name: 'EditPresentation', params: {id: presentation.id}}"><font-awesome-icon icon="edit" class="icon"/>Redigera</router-link>
          <router-link class="btn" :to="{name: 'Presentation', params: {id: presentation.id}}"><font-awesome-icon icon="play" class="icon"/>Starta</router-link>
        </div>
      </div>
      <confirm-dialogue ref="confirmDialogue"></confirm-dialogue>
    </div>
    <div class="btn btn__large" @click="CREATEPRESENTATIONACTION"><font-awesome-icon icon="plus" class="icon"/>Skapa presentation</div>
  </div>
</template>

<script>
import * as fb from "../firebase";
import { mapGetters, mapActions } from "vuex";
import ConfirmDialogue from '../components/ConfirmDialogue.vue'
const serverName = "https://tafmekpresentation.se"

export default {
  components: {ConfirmDialogue},
  methods: {
    ...mapActions(["getPresentationsAction", "CREATEPRESENTATIONACTION"]),
    handleCopy(id, $event) {
      this.$refs.copybox.value = serverName + "/presentation/" + id
      this.$refs.copybox.select();
      this.$refs.copybox.setSelectionRange(0, 99999); /* For mobile devices */
      document.execCommand("copy");
      this.$refs.copybox.value = ''
      let copiedAlert = $event.target.querySelector(".copied-alert")
      copiedAlert.classList.add("show")
      setTimeout(() => {copiedAlert.classList.remove("show")}, 1000)
    },
    async doDelete(presId) {
      await this.$refs.confirmDialogue.show({
          title: 'Radera presentation',
          message: 'Är du säker på att du vill ta bort presentationen?',
          okButton: 'Radera presentationen',
          cancelButton: 'Avbryt allt',
          dangerColors: true,
      })
      // If you throw an error, the method will terminate here unless you surround it wil try/catch
      fb.db.collection("presentations").doc(presId).delete().then(() => {
        console.log("Document successfully deleted!")
        this.getPresentationsAction()
      }).catch((error) => {
          console.error("Error removing document: ", error);
      });
    },
  },
  computed: {
    ...mapGetters(["getPresentations"])
  },
  mounted() {
    this.getPresentationsAction()
  }
}
</script>

<style lang="scss" scoped>
.presentation__container {
  max-width: 60rem;
  margin: 1rem auto;
  padding: 0 2rem;
  text-align: left;
  display: flex;
  flex-direction: column;
}
.presentationlist__container {
  flex: 1 1 auto;
  margin-top: 1rem;
  margin-bottom: 4rem;
}
.presentation__list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: .5rem 0;
  padding: .5rem;
  text-align: left;
  transition: background-color 100ms ease-in-out;
  &:hover {
    background-color: #ddd;
  }
}
.copied-alert {
  position: absolute;
  left: -8rem;
  background-color: white;
  color: green;
  padding: 0 .5rem;
  border-radius: 2px;
  top: 0;
  height: 100%;
  display: flex;
  align-items: center;
  opacity: 0;
  transition: opacity 500ms ease-in-out;
  pointer-events: none;
}
.copied-alert.show {
  opacity: 1;
  transition: opacity 100ms ease-in-out;
}
#copybox {
  position: absolute;
  left: -9999px;
}
.button__delete {
  color: red;
  text-decoration: underline;
  cursor: pointer;
}
.linkgroup {
  display: flex;
  align-items: center;
}

</style>